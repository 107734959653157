/**
 * @typedef {Object} FilterState
 * @property {string} date
 * @property {string} category
 * @property {string} nameCode
 * @property {string} region
 * @property {string} bankCode
 * @property {string} currency
 * @property {string} type
 */

/**
 * Creates a unique key for storing filter state
 * @param {string} rowId - Unique identifier for the row
 * @param {number} level - Nesting level
 * @returns {string}
 */
export const createFilterStateKey = (rowId, level) => `${rowId}-${level}`;

/**
 * Manages filter states for nested table rows
 */
export class FilterStateManager {
    constructor() {
        this.filterStates = new Map();
    }

    /**
     * Gets or creates filter state for a specific row and level
     */
    getFilterState(rowId, level, parentFilters = null) {
        const key = createFilterStateKey(rowId, level);

        if (!this.filterStates.has(key)) {
            // Inherit parent filters if available
            this.filterStates.set(key, {
                ...parentFilters,
                level,
                rowId
            });
        }

        return this.filterStates.get(key);
    }

    /**
     * Updates filter state for a specific row and level
     */
    updateFilterState(rowId, level, updates) {
        const key = createFilterStateKey(rowId, level);
        const currentState = this.getFilterState(rowId, level);

        this.filterStates.set(key, {
            ...currentState,
            ...updates
        });

        return this.filterStates.get(key);
    }
}