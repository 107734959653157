export const sanitiseParams = (params) => {
    const newParams = {...params };
    for (const key in newParams) {
        if (Object.prototype.hasOwnProperty.call(newParams, key)) {
            const element = newParams[key];
            if (element === undefined || element === null || element === "") {
                delete newParams[key];
            }
        }
    }
    return newParams;
};