import { useState } from 'react';

export const useFormState = (initialState) => {
    const [formState, setFormState] = useState(initialState);

    const onChangeParams = (newState) => {
        setFormState(newState); // Replace the entire state object
    };

    return [formState, onChangeParams];
};