export const getUserId = () => localStorage.getItem('sub_Id')

export function numberWithCommas(x, ignoreDecimal = false, noOfDec = 2) {
    if (isNaN(x) || x == null) { return x }

    const parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (!ignoreDecimal) {
        var part2 = `.${parts.length > 1 ? parts[1] : ''}`;
        for (var i = part2.length; i <= noOfDec; i++)
            part2 += '0';
        return parts[0] + part2;
    } else {
        return parts.length > 0 ? parts[0] : 0;
    }
}

export const formatNumber = (value, decimal = 0) => {
    if (typeof value === 'string') {
        return value;
    }
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
    }).format(value);
};

export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }).format(date);
};

export const getMonthFromDate = (dateString, previous) => {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const date = new Date(dateString); // Parse the date string
    const monthIndex = date.getMonth(); // Get the month index (0-11)
    if (previous) {
        return monthNames[monthIndex === 0 ? 11 : monthIndex - 1];
    }
    return monthNames[monthIndex]; // Return the month name
};



export const getYearFromDate = (dateString, previous) => {
    const date = new Date(dateString); // Parse the date string
    const year = date.getFullYear(); // Get the current year
    const month = date.getMonth(); // Get the current month (0-11)

    if (previous && month === 0) {
        // If it's January (month 0), return the previous year
        return year - 1;
    }

    return year; // Otherwise, return the current year
};