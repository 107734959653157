import { useState } from 'react';

export const useActionState = (initialState) => {
    const [actionState, setActionState] = useState(initialState);

    const onChangeActions = (newState) => {
        setActionState(newState); // Replace the entire state object
    };

    return [actionState, onChangeActions];
};