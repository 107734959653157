import React from 'react';
import styles from './MultiCategoryTable.module.css';

const formatNumber = (value) => {
  return new Intl.NumberFormat('en-US').format(value);
};

const MultiCategoryTable = ({ label, countryList, clientData }) => {
  // Create a map of country totals for quick lookup
  const countryTotals = new Map(
    countryList?.map((item) => [item.itemName, item.totalUsd])
  );

  // Create a map of client data for quick lookup
  const clientDataMap = new Map(
    clientData?.map((client) => [
      client.clientCode,
      new Map(client.items?.map((item) => [item.itemName, item.totalUsd])),
    ])
  );

  return (
    <div className={styles.dataTableContainer}>
      <table className={styles.dataTable}>
        <thead>
          <tr>
            <th>{label}</th>
            {clientData?.map((client) => (
              <th key={client.clientCode}>{client.clientCode}</th>
            ))}
            <th>Grand Total</th>
          </tr>
        </thead>
        <tbody>
          {countryList?.map((country) => (
            <tr key={country.itemName}>
              <td>{country.itemName}</td>
              {clientData?.map((client) => {
                const value =
                  clientDataMap
                    ?.get(client.clientCode)
                    ?.get(country.itemName) || 0;
                return (
                  <td
                    key={`${country.itemName}-${client.clientCode}`}
                    className={styles.numberCell}
                  >
                    {/* {value > 0 && (
                      <ControlPointIcon
                        fontSize="small"
                        onClick={() => handleOnClick(country.itemName)}
                      />
                    )} */}
                    {formatNumber(value)}
                  </td>
                );
              })}
              <td className={styles.numberCell}>
                {formatNumber(countryTotals.get(country.itemName) || 0)}
              </td>
            </tr>
          ))}
          <tr className={styles.grandTotalRow}>
            <td>Grand Total</td>
            {clientData?.map((client) => (
              <td
                key={`total-${client.clientCode}`}
                className={styles.numberCell}
              >
                {formatNumber(client.grandTotalUsd)}
              </td>
            ))}
            <td className={styles.numberCell}>
              {formatNumber(
                countryList?.reduce((acc, country) => acc + country.totalUsd, 0)
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MultiCategoryTable;
