import { dateFilter, numberFilter, textFilter } from 'react-bootstrap-table2-filter'
import { React } from 'react'
import { numberWithCommas } from '../helpers/common'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import { EditOutlined, DeleteOutlineOutlined, AddComment, AttachFile } from '@mui/icons-material'

export const columns = ({ handleEdit = () => {}, handleDelete = () => {}, roleId = [], handleAddComment = () => {}, handleAttachment = () => {} }) => [
  {
    dataField: 'Id',
    text: 'No',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'No'
    })
  },
  {
    dataField: 'Group',
    text: 'Client Code',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Client Code'
    })
  },
  {
    dataField: 'BOName',
    text: 'Name',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Name'
    })
  },
  /*
  {
    dataField: 'BOName',
    text: 'BO Group',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'BO Group'
    })
  },  
  */
  {
    dataField: 'BOType',
    text: 'Client Type',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Client Type'
    })
  },
  {
    dataField: 'BankName',
    text: 'Bank',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Bank'
    })
  },
  {
    dataField: 'Region',
    text: 'Country',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Country'
    })
  },
  {
    dataField: 'AcctType',
    text: 'Type',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Type'
    })
  },  
  {
    dataField: 'AccountNo',
    text: 'A/C No.',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'A/C No.'
    })
  },
  {
    dataField: 'Currency',
    text: 'Currency',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Currency'
    })
  },
  {
    dataField: 'PreviousBalance',
    text: 'Previous Balance',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Previous Balance'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      row.PreviousBalance = row.PreviousBalance ?? '-';
      var color = `color: ${ row.PreviousBalance == '-' ? '#fff' : row.PreviousBalance >= 0 ? '#67FF77' : '#FF6967'};`;
      return (
        <span Style={color}>
          {
            `${numberWithCommas(row.PreviousBalance, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'CurrentBalance',
    text: 'Current Balance',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Current Balance'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      var color = `color: ${ row.CurrentBalance == '-' ? '#fff' : row.CurrentBalance >= 0 ? '#67FF77' : '#FF6967'};`;
      return (
        <span Style={color}>
          {
            `${numberWithCommas(row.CurrentBalance, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'Movements',
    text: 'Movement',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Movement'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      row.Movements = row.Movements ?? '-';
      var color = `color: ${ row.Movements == '-' ? '#fff' : row.Movements >= 0 ? '#67FF77' : '#FF6967'};`;
      return (
        <span Style={color}>
          {
            `${numberWithCommas(row.Movements, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'FXRate',
    text: 'FX Rate',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'FX Rate'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {
            `${numberWithCommas(row.FXRate, false, 5)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'PreviousBalanceUSD',
    text: 'Previous Balance USD',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Previous Balance USD'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      row.PreviousBalanceUSD = row.PreviousBalanceUSD ?? '-';
      var color = `color: ${ row.PreviousBalanceUSD == '-' ? '#fff' : row.PreviousBalanceUSD >= 0 ? '#67FF77' : '#FF6967'};`;
      return (
        <span Style={color}>
          {
            `${numberWithCommas(row.PreviousBalanceUSD, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'CurrentBalanceUSD',
    text: 'Current Balance USD',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Current Balance USD'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      var color = `color: ${ row.CurrentBalanceUSD == '-' ? '#fff' : row.CurrentBalanceUSD >= 0 ? '#67FF77' : '#FF6967'};`;
      return (
        <span Style={color}>
          {
            `${numberWithCommas(row.CurrentBalanceUSD, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'MovementsUSD',
    text: 'Movements USD',
    sort: true,
    toggle: true,
    width: 500,
    filter: numberFilter({
      placeholder: 'Movements USD'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      row.MovementsUSD = row.MovementsUSD ?? '-';
      var color = `color: ${ row.MovementsUSD == '-' ? '#fff' : row.MovementsUSD >= 0 ? '#67FF77' : '#FF6967'};`;
      return (
        <span Style={color}>
          {
            `${numberWithCommas(row.MovementsUSD, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'LastUpdate',
    text: 'Last Update per Bank',
    sort: true,
    toggle: true,
    width: 150,
    filter: dateFilter({
      dateFormat: 'mm/dd/yyyy',
      placeholder: 'custom placeholder'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      const date = moment(row.LastUpdate).format('Do MMMM YYYY')
      return (
        <p
          style={{
            color: 'white',
            // width: '110%',
            fontSize: '14px',
            display: 'inline-block'
          }}
        >
            <span style={{ color: 'white', fontSize: '14px' }}>
              {date}
            </span>
        </p>
      )
    }
  },
  {
    dataField: 'Remarks',
    text: 'Remark',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Remark'
    })
  },
  {
    dataField: 'action',
    text: 'Action',
    sort: false,
    toggle: false,
    csvExport: false,
    width: 50,
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          <Tooltip title={'edit'} style={{ cursor: 'pointer', color: '#FFC107' }}>
            <EditOutlined onClick={() => handleEdit(row)}/>
          </Tooltip>

          <Tooltip title={'comment'} style= {{ cursor: 'pointer', color: 'chocolate' }}>
            <AddComment onClick={() => handleAddComment(row)} />
          </Tooltip>

          <Tooltip title={'attachment'} style= {{ cursor: 'pointer', color: 'chocolate' }}>
            <AttachFile onClick={() => handleAttachment(row)} />
          </Tooltip>

          {
            (roleId.includes('superadmin') || roleId.includes('admin')) && (
              <Tooltip title={'delete'} style={{ cursor: 'pointer', color: '#b30000' }}>
                <DeleteOutlineOutlined onClick={() => handleDelete(row)}/>
              </Tooltip>
            )
          }
        </span>
      )
    }
  }
]
