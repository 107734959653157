import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Modal } from 'react-bootstrap';
import {
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  TextField,
  Button,
  Menu,
  useStepContext,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PivotTableV2 from './components/NestedPivotTable.jsx';
import DateGridHelper from './components/utils/tableHelpers.js';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Dialog from '@mui/material/Dialog';
import MultiCategoryTable from './components/MultiCategoryTable.jsx';
import NestedTableBreakdown from './components/NestedTable.jsx';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useNestedTableState } from './hooks/useNestedTableState.js';
import NestedTable from './components/NestedTableV3.jsx';
import { useActionState } from './hooks/useActionState.js';
import {
  formatNumber,
  getMonthFromDate,
  getYearFromDate,
} from '../helpers/common.js';

const filterList = [
  { label: 'Client', value: 'byClient' },
  { label: 'Country', value: 'byCountry' },
  { label: 'Bank', value: 'byCountryBank' },
  { label: 'Currency', value: 'byCurrency' },
  { label: 'Name', value: 'byName' },
  { label: 'Account Type', value: 'byType' },
];

const ARRAY_CODES = {
  byClient: ['G11', 'G21', 'G22', 'G23', 'G31', 'G32', 'G91'],
  byCountry: [
    'HKG',
    'TWN',
    'JPN',
    'MUS',
    'SGP',
    'KOR',
    'UAE',
    'MAC',
    'MNE',
    'CHE',
    'BHS',
  ],
  byCountryBank: [
    'PBB HKG',
    'BOT TWN',
    'CTBC JPN',
    'AFB MUS',
    'MBB SGP',
    'UOB SGP',
    'KEB KOR',
    'ENBD UAE',
    'CBD UAE',
    'AMC UAE',
    'CTBC TWN',
    'CTBC HKG',
    'EIB UAE',
    'WIO UAE',
    'DIB UAE',
    'MB TWN',
    'WLB HKG',
    'CCB HKG',
    'BOC MAC',
    'UCB MNE',
    'SCB UAE',
    'SQB CHE',
    'SB TWN',
    'DB BHS',
    'BOCM HKG',
    'SMBC JPN',
  ],
  byCurrency: [
    'AED',
    'AUD',
    'CAD',
    'CHF',
    'CNY',
    'EUR',
    'GBP',
    'HKD',
    'JPY',
    'KRW',
    'MOP',
    'MUR',
    'SGD',
    'THB',
    'USD',
  ],
  byType: ['CA/SA', 'Investment', 'Term Deposit	'],
};

const axios = require('axios');

const postAsync = async (url, payload, header = null) => {
  const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

  try {
    var headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (header) headers = Object.assign(headers, header);
    const token = localStorage.getItem('newApiToken');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    var resp = await axios({
      headers: headers,
      method: 'POST',
      url: `${base_url}/${url}`,
      data: payload,
    });

    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const MultiCategoryPivotPage = () => {
  const [dashboardData, setDashboardData] = useState({
    portfolios: [],
  });
  const [byCountry, setByCountry] = useState([]);
  const [byCountryAndBank, setByCountryAndBank] = useState([]);
  const [byCurrency, setByCurrency] = useState([]);
  const [byCurrencyAndType, setByCurrencyAndType] = useState([]);
  const [showZoom, setShowZoom] = useState(false);
  const [zoomContent, setZoomContent] = useState();
  const [filters, setFilters] = useState({
    portfolio: {
      PortfolioId: '',
      PortfolioName: '',
    },
    monthYear: new Date(),
    countries: {
      countryList: [],
      countrySelected: [],
    },
    bankAndCountry: {
      list: [],
      selected: [],
    },
    currencies: {
      list: [],
      selected: [],
    },
    currencyType: {
      list: [],
      selected: [],
    },
  });
  const [responseData, setResponseData] = useState([]);
  const [responseData2, setResponseData2] = useState();
  const [categoryFilter, setCategoryFilters] = useState([{}]);
  const [currentCategory, setCurentCategory] = useState(filterList[0].value);
  const [filteredData, setFilteredData] = useState([]);
  const [tableDataConfig, setTableDataConfig] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [openBreakdownModal, setOpenBreakdownModal] = useState(false);
  const [modalLabel, setModalLabel] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBreakdownOptions, setSelectedBreakdownOptions] = useState([
    currentCategory,
  ]);
  const [selectedBreakdownCategory, setSelectedBreakdownCategory] = useState(
    filterList[0].value
  );
  const [currentSelectedRecord, setCurrentSelectedRecord] = useState();
  const [currentBreakdownPayload, setCurrentBreakdownPayload] = useState();
  const [currentMonthSelected, setCurrentMonthSelected] = useState(
    getMonthFromDate(filters.monthYear)
  );
  const [previousMonthSelected, setPreviousMonthSelected] = useState(
    getMonthFromDate(filters.monthYear, true)
  );
  const [currentYearSelected, setCurrentYearSelected] = useState(
    getYearFromDate(filters.monthYear)
  );

  const [previousYearSelected, setPreviousYearSelected] = useState(
    getYearFromDate(filters.monthYear, true)
  );

  const { fetchNestedDataWithFilters } = useNestedTableState();

  useEffect(() => {
    responseDataConfig();
    setFilteredData([]);
  }, [
    currentCategory,
    currentMonthSelected,
    currentYearSelected,
    previousYearSelected,
  ]);

  useEffect(() => {
    const init = async () => {
      var results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });

      var pf =
        results.result.portfolios.length > 0
          ? results.result.portfolios[0]
          : { PortfolioId: '', PortfolioName: '' };
      filters.portfolio = pf;
      setFilters({ ...filters });
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });

      setResponseData(results.result);
    };

    setCurrentMonthSelected(getMonthFromDate(filters.monthYear));
    setPreviousMonthSelected(getMonthFromDate(filters.monthYear, true));
    setCurrentYearSelected(getYearFromDate(filters.monthYear));
    setPreviousYearSelected(getYearFromDate(filters.monthYear, true));

    init();
  }, [filters.monthYear, filters.portfolio]);

  const handleSearch = (property, searchValue) => {
    const searchedData = responseData[property]?.items;

    if (property === 'byClient') {
      const filtered = searchedData.filter((item) =>
        item.code.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byCountry') {
      const filtered = searchedData.filter((item) =>
        item.region.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byCountryBank') {
      const filtered = searchedData.filter((item) =>
        item.bankName.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byCurrency') {
      const filtered = searchedData.filter((item) =>
        item.currency.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byName') {
      const filtered = searchedData.filter((item) =>
        item.code.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    if (property === 'byType') {
      const filtered = searchedData.filter((item) =>
        item.type.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    }
    setSearchFilter(searchValue);
  };

  const handleViewMoreRecords = async (record) => {
    if (currentCategory === 'byClient') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&ClientCode=${record.code}&date=${filters.monthYear}`,
        '_blank'
      );
    }

    if (currentCategory === 'byCountry') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Region=${record.region}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byCountryBank') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Region=${record.region}&BankCode=${record.bankCode}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byCurrency') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Currency=${record.currency}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byName') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&NameCode=${record.code}&date=${filters.monthYear}`,
        '_blank'
      );
    }
    if (currentCategory === 'byType') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Type=${record.type}&date=${filters.monthYear}`,
        '_blank'
      );
    }
  };

  const handleOpenModalBreakdown = async (value) => {
    let body = {
      nameCode: null,
      region: null,
      bankCode: null,
      currency: null,
      clientCode: null,
      type: null,
    };

    setCurrentBreakdownPayload((prev) => body);

    const results = await postAsync('treasure/multiplebreakdown', {
      date: filters.monthYear,
      category: currentCategory,
      ...body,
    });

    setResponseData2(results.result);
    setOpenBreakdownModal(true);
  };

  const handleOnCloseModalBreakdown = () => {
    setOpenBreakdownModal(false);
  };

  const spanStyle = (value) => {
    if (value > 0) {
      return { color: 'green' };
    }
    if (value < 0) {
      return { color: 'red' };
    }
  };

  const responseDataConfig = () => {
    if (currentCategory === 'byClient') {
      setTableDataConfig([
        { dataIndex: 'code', label: 'Client' },
        {
          dataIndex: 'prevBalanceUSD',
          label: `USD ${previousMonthSelected} ${previousYearSelected}`,
        },
        {
          dataIndex: 'currentBalanceUSD',
          label: `USD ${currentMonthSelected} ${currentYearSelected}`,
        },
        {
          dataIndex: 'movement',
          label: 'Movement (USD)',
          render: (record) => {
            return (
              <span style={spanStyle(record.movement)}>
                {formatNumber(record.movement)}
              </span>
            );
          },
        },
      ]);
    }
    if (currentCategory === 'byCountry') {
      setTableDataConfig([
        { dataIndex: 'region', label: 'Country' },
        {
          dataIndex: 'prevBalanceUSD',
          label: `USD ${previousMonthSelected} ${previousYearSelected}`,
        },
        {
          dataIndex: 'currentBalanceUSD',
          label: `USD ${currentMonthSelected} ${currentYearSelected}`,
        },
        {
          dataIndex: 'movement',
          label: 'Movement (USD)',
          render: (record) => {
            return (
              <span style={spanStyle(record.movement)}>
                {formatNumber(record.movement)}
              </span>
            );
          },
        },
      ]);
    }
    if (currentCategory === 'byCountryBank') {
      setTableDataConfig([
        {
          dataIndex: 'bank_region',
          label: 'Bank',
          render: (record) => {
            // Spacing between bank code and region
            return <>{record.bankCode + ' ' + record.region}</>;
          },
        },
        // { dataIndex: 'bankCode', label: 'Bank Code' },
        { dataIndex: 'bankName', label: 'Bank Name' },
        {
          dataIndex: 'prevBalanceUSD',
          label: `USD ${previousMonthSelected} ${previousYearSelected}`,
        },
        {
          dataIndex: 'currentBalanceUSD',
          label: `USD ${currentMonthSelected} ${currentYearSelected}`,
        },
        {
          dataIndex: 'movement',
          label: 'Movement (USD)',
          render: (record) => {
            return (
              <span style={spanStyle(record.movement)}>
                {formatNumber(record.movement)}
              </span>
            );
          },
        },
      ]);
    }
    if (currentCategory === 'byCurrency') {
      setTableDataConfig([
        { dataIndex: 'currency', label: 'Currency' },
        {
          dataIndex: 'prevBalanceUSD',
          label: `USD ${previousMonthSelected} ${previousYearSelected}`,
        },
        {
          dataIndex: 'currentBalanceUSD',
          label: `USD ${currentMonthSelected} ${currentYearSelected}`,
        },
        {
          dataIndex: 'movement',
          label: 'Movement (USD)',
          render: (record) => {
            return (
              <span style={spanStyle(record.movement)}>
                {formatNumber(record.movement)}
              </span>
            );
          },
        },
      ]);
    }
    if (currentCategory === 'byName') {
      setTableDataConfig([
        // { dataIndex: 'category', label: 'Category' },
        { dataIndex: 'code', label: 'Name' },
        // { dataIndex: 'type', label: 'Type' },
        {
          dataIndex: 'prevBalanceUSD',
          label: `USD ${previousMonthSelected} ${previousYearSelected}`,
        },
        {
          dataIndex: 'currentBalanceUSD',
          label: `USD ${currentMonthSelected} ${currentYearSelected}`,
        },
        {
          dataIndex: 'movement',
          label: 'Movement (USD)',
          render: (record) => {
            return (
              <span style={spanStyle(record.movement)}>
                {formatNumber(record.movement)}
              </span>
            );
          },
        },
      ]);
    }
    if (currentCategory === 'byType') {
      setTableDataConfig([
        { dataIndex: 'type', label: 'Account Type' },
        {
          dataIndex: 'prevBalanceUSD',
          label: `USD ${previousMonthSelected} ${previousYearSelected}`,
        },
        {
          dataIndex: 'currentBalanceUSD',
          label: `USD ${currentMonthSelected} ${currentYearSelected}`,
        },
        {
          dataIndex: 'movement',
          label: 'Movement (USD)',
          render: (record) => {
            return (
              <span style={spanStyle(record.movement)}>
                {formatNumber(record.movement)}
              </span>
            );
          },
        },
      ]);
    }
  };

  return (
    <Container fluid>
      <Modal
        className="dashboard-modal"
        show={showZoom}
        size="lg"
        top
        aria-labelledby="zoom-header"
        onHide={() => setShowZoom(false)}
      >
        <Modal.Body>
          <Row className="pms-dashboard">
            <Col lg={12}>{zoomContent}</Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Row>
        <Col lg={12}>
          <Row style={{ marginBottom: '8px' }}>
            <span className="p-2 pageheader">
              <h3 className="pagetitle">Treasury Dashboard</h3>
            </span>
          </Row>
        </Col>
      </Row>

      <Row className="pms-dashboard" style={{ marginTop: '30px' }}>
        <Col lg={12} style={{ marginBottom: '20px' }}>
          <span style={{ color: 'white' }}>Filter by: </span>
          &nbsp;&nbsp;
          <Select
            value={currentCategory}
            onChange={(event) => {
              setCurentCategory(event.target.value);
            }}
            input={<OutlinedInput label={currentCategory} />}
            // renderValue={(selected) => filter[filterKey][textKey]}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiMenuItem-root:hover': {
                    backgroundColor: 'lightgrey',
                    color: 'black',
                  },
                  '& .MuiMenuItem-root.Mui-selected:hover': {
                    backgroundColor: 'lightgrey',
                    color: 'black',
                  },
                  '& .MuiCheckbox-root': {
                    color: 'white',
                  },
                  '& .MuiList-root': {
                    background: 'rgb(31, 33, 37) !important',
                    color: 'white !important',
                    width: '250px',
                  },
                  '& .MuiListItemText-primary': {
                    fontSize: '13px !important',
                  },
                },
              },
            }}
            sx={{
              width: '250px',
              height: '27px',
              border: '1px solid #d9d9d9 !important',
              '.MuiButtonBase-root': {
                color: 'white',
              },
            }}
            style={{
              borderRadius: '4px',
              width: '200px !important',
              height: '32px !important',
              fontSize: '14px',
              left: '0px',
            }}
          >
            {filterList?.map((row, idx) => (
              <MenuItem key={idx} value={row.value}>
                <ListItemText primary={row.label} />
              </MenuItem>
            ))}
          </Select>
          &nbsp;&nbsp;
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // label={'Month'}
              views={['month', 'year']}
              renderInput={(props) => (
                <TextField {...props} size="small" helperText={null} />
              )}
              onChange={(newValue) => {
                filters.monthYear = newValue;
                setFilters({ ...filters });
              }}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root': {
                    border: '1px solid #fff',
                    height: '27px',
                  },
                  '& .MuiInputLabel-outlined': {
                    border: '1px solid #FF0000 !important',
                  },
                },
              }}
              value={filters.monthYear}
              openTo="month"
            />
          </LocalizationProvider>
          &nbsp;&nbsp;
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleOpenModalBreakdown(null)}
            endIcon={<ControlPointIcon />}
            sx={{
              color: '#ffc107',
              fontSize: 12,
              borderColor: '#ffc107',
              height: '27px',
              marginBottom: '1px',
            }}
            // sx={{
            //   color: '#ffc107',
            //   // padding: '2px',
            //   fontSize: 12,
            //   marginTop: '1.7rem',
            //   marginLeft: '1.5rem',
            //   borderColor: '#ffc107',
            //   padding: '.5rem ',
            //   height: '1.9rem',
            //   alignItems: 'center',
            // }}
          >
            Client Breakdown
          </Button>
        </Col>
      </Row>

      {responseData.byClient && (
        <NestedTable
          dataSource={
            filteredData.length > 0
              ? filteredData
              : responseData[currentCategory]?.items.map((item, index) => ({
                  ...item,
                  id: index,
                  // to allow search using space in column search
                  bank_region: item.bankCode + ' ' + item.region,
                }))
          }
          dataConfig={[...tableDataConfig]}
          dateFilter={filters.monthYear}
          currentCategory={currentCategory}
          grandTotal={responseData[currentCategory]?.grandTotal}
          prevGrandTotal={responseData[currentCategory]?.prevGrandTotal}
          movementTotal={responseData[currentCategory]?.movement}
          currentYearSelected={currentYearSelected}
          currentMonthSelected={currentMonthSelected}
        />
      )}

      <Dialog
        onClose={handleOnCloseModalBreakdown}
        open={openBreakdownModal}
        maxWidth={'xl'}
        fullWidth
      >
        <MultiCategoryTable
          label={modalLabel}
          countryList={responseData2?.itemGroup}
          clientData={responseData2?.clientGroup}
        />
      </Dialog>
    </Container>
  );
};

export default MultiCategoryPivotPage;
