import React, { useEffect, useState } from 'react';
import styles from './Table.module.css';
import TableRow from './TableRowV3';
import { useFormState } from '../hooks/useFormState';
import { useActionState } from '../hooks/useActionState';
import { formatNumber, formatDate } from '../../helpers/common';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const ARRAY_CODES = {
  byClient: ['G11', 'G21', 'G22', 'G23', 'G31', 'G32', 'G91'],
  byCountry: [
    'HKG',
    'TWN',
    'JPN',
    'MUS',
    'SGP',
    'KOR',
    'UAE',
    'MAC',
    'MNE',
    'CHE',
    'BHS',
  ],
  byCountryBank: [
    'PBB HKG',
    'BOT TWN',
    'CTBC JPN',
    'AFB MUS',
    'MBB SGP',
    'UOB SGP',
    'KEB KOR',
    'ENBD UAE',
    'CBD UAE',
    'AMC UAE',
    'CTBC TWN',
    'CTBC HKG',
    'EIB UAE',
    'WIO UAE',
    'DIB UAE',
    'MB TWN',
    'WLB HKG',
    'CCB HKG',
    'BOC MAC',
    'UCB MNE',
    'SCB UAE',
    'SQB CHE',
    'SB TWN',
    'DB BHS',
    'BOCM HKG',
    'SMBC JPN',
  ],
  byCurrency: [
    'AED',
    'AUD',
    'CAD',
    'CHF',
    'CNY',
    'EUR',
    'GBP',
    'HKD',
    'JPY',
    'KRW',
    'MOP',
    'MUR',
    'SGD',
    'THB',
    'USD',
  ],
  byType: ['CA/SA', 'Investment', 'Term Deposit'],
};
const axios = require('axios');

const postAsync = async (url, payload, header = null) => {
  const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

  try {
    var headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (header) headers = Object.assign(headers, header);
    const token = localStorage.getItem('newApiToken');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    var resp = await axios({
      headers: headers,
      method: 'POST',
      url: `${base_url}/${url}`,
      data: payload,
    });

    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const NestedTable = ({
  dataConfig,
  dataSource,
  dateFilter,
  currentCategory,
  usedOptionsBreakdown = [],
  usedPayloadBreakdown,
  grandTotal,
  prevGrandTotal,
  movementTotal,
  searchColumn = true,
  level = 0,
  currentMonthSelected,
  currentYearSelected,
}) => {
  const [expandedRows, setExpandedRows] = useState(new Map());
  const [usedOptions, setUsedOptions] = useState([]);
  const [usedPayload, setUsedPayload] = useState(usedPayloadBreakdown);
  const [formState, setFormState] = useFormState(usedPayloadBreakdown);
  const [initCategory, setInitCategory] = useState(currentCategory);
  const [actionState, pushToActions, actionStateRef] = useActionState([
    currentCategory,
  ]);
  const [actionArrayState, setActionArrayState] = useActionState([
    ...usedOptionsBreakdown,
    { rowId: null, option: currentCategory },
  ]);
  const [categoryLabel, setCategoryLabel] = useState(null);
  const [sortedDataSource, setSortedDataSource] = useState(dataSource);
  const [searchFilters, setSearchFilters] = useState({});

  useEffect(() => {
    if (currentCategory !== initCategory) {
      setActionArrayState([{ rowId: null, option: currentCategory }]);
      setExpandedRows(new Map());
      setFormState({});
      setInitCategory(currentCategory);
    }
  }, [currentCategory]);

  useEffect(() => {
    setSortedDataSource(dataSource);
    onClickHeaderSortingAsc(dataConfig[0].dataIndex);
  }, [dataSource]);

  const showGrandTotal = (index) => {
    if (index === 0) {
      return 'Grand Total';
    }

    if (index === dataConfig.length - 3) {
      return formatNumber(prevGrandTotal);
    }
    if (index === dataConfig.length - 2) {
      return formatNumber(grandTotal);
    }
    if (index === dataConfig.length - 1) {
      return formatNumber(movementTotal);
    }
  };

  const findCategoryName = (value, arrays) => {
    if (value === undefined) {
      return null;
    }

    for (const [arrayName, array] of Object.entries(arrays)) {
      if (array.includes(value)) {
        return arrayName;
      }
    }
    return 'byName';
  };

  const findCategoryLabel = (value) => {
    if (value === 'byClient') return 'Client';
    if (value === 'byCountry') return 'Country';
    if (value === 'byCountryBank') return 'Bank';
    if (value === 'byCurrency') return 'Currency';
    if (value === 'byType') return 'Account Type';
    return 'Name';
  };

  const isAccountTypeValue = (value) => {
    const accountTypes = ['CA/SA', 'Investment', 'Term Deposit'];
    if (accountTypes.includes(value)) {
      return value;
    }
    // if (value === 'CA/SA') return value;
    // if (value === 'Investment') return value;
    // if (value === 'Term Deposit') return value;
    return null;
  };

  const isValidClientCode = (value) => {
    if (value === 'G11') return value;
    if (value === 'G21') return value;
    if (value === 'G22') return value;
    if (value === 'G23') return value;
    if (value === 'G31') return value;
    if (value === 'G32') return value;
    if (value === 'G91') return value;
    return null;
  };

  const isValidNameCode = (value) => {
    const codes = ['G11', 'G21', 'G22', 'G23', 'G31', 'G32', 'G91'];
    // if (
    //   value !== 'G11' ||
    //   value !== 'G21' ||
    //   value !== 'G22' ||
    //   value !== 'G23' ||
    //   value !== 'G31' ||
    //   value !== 'G32' ||
    //   value !== 'G91'
    // ) {
    //   return value;
    // }

    if (!codes.includes(value)) {
      return value;
    }

    return null;
  };

  const handleOptionClick = async (rowId, option, rowData) => {
    const label = findCategoryLabel(option.option);
    setActionArrayState(() => [
      ...actionArrayState,
      { rowId: rowId, option: option.option, level: level },
    ]);
    setCategoryLabel(() => label);
    let optionArr = usedOptions;

    let body = {
      category: option.option,
      nameCode:
        isValidNameCode(rowData.code) ??
        isValidNameCode(formState?.nameCode) ??
        null,
      region: rowData.region ?? formState?.region ?? null,
      bankCode: rowData.bankCode ?? formState?.bankCode ?? null,
      currency: rowData.currency ?? formState?.currency ?? null,
      type: isAccountTypeValue(rowData.type) ?? formState?.type ?? null,
      clientCode:
        isValidClientCode(rowData.code) ?? formState?.clientCode ?? null,
    };
    const dataToParse = findCategoryName(rowData.itemName, ARRAY_CODES);

    if (dataToParse) {
      if (dataToParse === 'byType') {
        body = { ...body, type: isAccountTypeValue(rowData.itemName) };
      }
      if (dataToParse === 'byCurrency') {
        body = { ...body, currency: rowData.itemName };
      }
      if (dataToParse === 'byCountry') {
        body = { ...body, region: rowData.itemName };
      }
      if (dataToParse === 'byCountryBank') {
        const bankCode = rowData.itemName.split(' ')[0];
        const region = rowData.itemName.split(' ')[1];
        body = { ...body, bankCode: bankCode };

        // if (body.region === null) {
        //   body = { ...body, region: region, bankCode: bankCode };
        // }
      }
      if (dataToParse === 'byName') {
        body = {
          ...body,
          nameCode: isValidNameCode(rowData.itemName) || rowData.code,
        };
      }

      if (dataToParse === 'byClient') {
        body = { ...body, clientCode: isValidClientCode(rowData.itemName) };
      }
    }
    setFormState(() => body);
    pushToActions(() => [...actionState, option]);

    try {
      const results = await postAsync('treasure/multiplebreakdown', {
        ...body,
        date: dateFilter,
      });

      setExpandedRows((prev) => {
        const newMap = new Map(prev);
        newMap.set(rowId, {
          data: results.result.itemGroup,
          option,
        });
        return newMap;
      });
      optionArr.push(option);

      setUsedOptions((prev) => {
        const newArray = [...prev, { rowId: rowId, option: option.option }];
        return newArray;
      });
    } catch (error) {
      console.error('Error fetching nested data:', error);
    }

    setUsedPayload(() => {
      return body;
    });
  };

  const handleCloseNested = (rowId) => {
    setActionArrayState((prev) => {
      const newFilters = actionArrayState.filter(
        (item) => item.level !== level
      );
      return newFilters;
    });

    setExpandedRows((prev) => {
      const newMap = new Map(prev);
      // Removes the entry for the specified rowId
      newMap.delete(rowId);
      return newMap;
    });
  };
  const onClickHeaderSortingAsc = (dataIndex) => {
    const sortedData = [...dataSource].sort((a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return -1; // null is smaller
      if (valueB === null) return 1; // null is smaller

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }

      return Number(valueA) - Number(valueB);
    });
    setSortedDataSource(sortedData);
  };

  const onClickHeaderSortingDesc = (dataIndex) => {
    const sortedData = [...dataSource].sort((a, b) => {
      const valueA = a[dataIndex];
      const valueB = b[dataIndex];

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return 1; // null is larger
      if (valueB === null) return -1; // null is larger

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueB - valueA;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueB.localeCompare(valueA);
      }

      return Number(valueB) - Number(valueA);
    });
    setSortedDataSource(sortedData);
  };

  const handleFilterChange = (dataIndex, value) => {
    const newFilters = {
      ...searchFilters,
      [dataIndex]: value.toLowerCase(),
    };

    setSearchFilters(newFilters);

    const allFiltersEmpty = Object.values(newFilters).every(
      (filterValue) => filterValue === ''
    );

    // if (onSearch) {
    //   return onSearch(newFilters);
    // }

    if (allFiltersEmpty) {
      setSortedDataSource(dataSource);
      onClickHeaderSortingAsc(dataConfig[0].dataIndex);
      return;
    }

    const filtered = dataSource.filter((record) => {
      return Object.keys(newFilters).every((key) => {
        const cellValue = record[key];
        const filterValue = newFilters[key];

        if (!filterValue) return true;

        if (cellValue === null || cellValue === undefined) {
          return false;
        }

        // Special handling for date fields
        if (key === 'recordDate' || key === 'date') {
          const formattedDate = formatDate(cellValue).toLowerCase();
          return formattedDate.includes(filterValue);
        }

        if (
          key === 'prevBalanceUSD' ||
          key === 'currentBalanceUSD' ||
          key === 'movementUSD'
        ) {
          const normalizedCellValue = parseFloat(cellValue)
            .toFixed(2)
            .toString(); // Normalize raw value
          const formattedCellValue = formatNumber(cellValue).toLowerCase(); // Formatted value for display
          return (
            formattedCellValue.includes(filterValue) ||
            normalizedCellValue.includes(filterValue)
          );
        }

        const stringValue = cellValue.toString().toLowerCase();
        return stringValue.includes(filterValue);
      });
    });

    setSortedDataSource(filtered);
  };

  const handleClearFilters = () => {
    setSearchFilters({});
    setSortedDataSource(dataSource);
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr>
            {dataConfig.map((config) => (
              <th key={config.dataIndex}>
                <div style={{ display: 'flex' }}>
                  {config.label}

                  {config.dataIndex !== 'action' && (
                    <div style={{ marginLeft: 3 }}>
                      <KeyboardDoubleArrowUpIcon
                        onClick={() =>
                          onClickHeaderSortingAsc(config.dataIndex)
                        }
                        sx={{ fontSize: 20 }}
                      />
                      <KeyboardDoubleArrowDownIcon
                        onClick={() =>
                          onClickHeaderSortingDesc(config.dataIndex)
                        }
                        sx={{ fontSize: 20 }}
                      />
                    </div>
                  )}
                </div>
                {config.dataIndex !== 'action' && searchColumn ? (
                  <>
                    <input
                      type="text"
                      value={searchFilters[config.dataIndex] || ''}
                      placeholder={`${config.label}`}
                      onChange={(e) =>
                        handleFilterChange(config.dataIndex, e.target.value)
                      }
                      className={styles.tableInput}
                    />
                  </>
                ) : (
                  <>
                    <button
                      // onClick={handleClearFilters}
                      className={styles.actionButton}
                    >
                      Clear Filters123
                    </button>
                  </>
                )}
              </th>
            ))}
            <th style={{ display: 'flex', flexDirection: 'column' }}>
              Actions
              <button
                onClick={handleClearFilters}
                className={styles.actionButton2}
              >
                <span style={{ padding: 1 }}>Clear Filters</span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {sortedDataSource?.map((row, index) => (
            <TableRow
              key={index}
              rowId={index}
              row={row}
              dataConfig={[...dataConfig]}
              expandedData={expandedRows.get(index)}
              usedOptions={actionArrayState}
              onOptionClick={handleOptionClick}
              onCloseNested={handleCloseNested}
              level={level}
              currentCategory={currentCategory}
              dateFilter={dateFilter}
              usedPayloadBreakdown={formState}
              categoryLabel={categoryLabel}
              currentMonthSelected={currentMonthSelected}
              currentYearSelected={currentYearSelected}
            />
          ))}
          {grandTotal && (
            <tr>
              {dataConfig.map((_, index) => (
                <>
                  <td style={{ fontWeight: 'bold' }} key={`empty-${index}`}>
                    {showGrandTotal(index)}
                  </td>
                </>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NestedTable;
