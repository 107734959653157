import { useState, useCallback } from 'react';
import { FilterStateManager } from '../components/utils/filterState';

export const useNestedTableState = () => {
    const [filterManager] = useState(() => new FilterStateManager());
    const [expandedState, setExpandedState] = useState(new Map());
    const [selectedMenu, setSelectedMenu] = useState([]);


    const fetchNestedDataWithFilters = useCallback(async(
        parentRow,
        action,
        level,
        postAsync,
        dateFilter
    ) => {

        // Get filters for this specific row/level
        const filters = filterManager.getFilterState(
            parentRow.id,
            level,
            // level === 1 ? { // Root level inherits from parent
            //     // date: dateFilter,
            //     nameCode: parentRow.code,
            // } : null
        );

        // Update filters with new action
        filterManager.updateFilterState(
            parentRow.id,
            level, { category: action }
        );

        const results = await postAsync('treasure/multiplebreakdown', {
            date: dateFilter,
            category: action,
            nameCode: filters.nameCode,
            region: filters.region || null,
            bankCode: filters.bankCode || null,
            currency: filters.currency || null,
            type: filters.type || null,
        });

        return results.result.itemGroup;
    }, [filterManager]);

    const toggleExpanded = useCallback((rowId, level, data, action) => {
        setExpandedState(prev => {
            const newState = new Map(prev);
            const key = `${rowId}-${level}`;
            const current = newState.get(key);

            if (current?.action === action && current?.isExpanded) {
                newState.delete(key);
            } else {
                newState.set(key, { data, action, isExpanded: true });
            }

            return newState;
        });
    }, []);

    const handleOnClickMenu = (action) => {
        if (!selectedMenu.includes(action)) {
            setSelectedMenu(prev => [...prev, action]);
        }
        // setSelectedMenu([...selectedMenu, action]);
    };

    return {
        fetchNestedDataWithFilters,
        toggleExpanded,
        expandedState,
        filterManager,
        handleOnClickMenu,
        selectedMenu
    };
};