class DateGridHelper {
    static exportToCsv = (filename, columns, dataSource, grandTotal = null) => {
        const SEPARATOR = ',';

        const header = columns.map((c) => c.label).join(SEPARATOR);

        const rows = dataSource.map((data) => {
            const rowData = [
                ...columns.map((c) => {
                    let value = data[c.dataIndex];

                    //   if (c.type === 'date' && value) {
                    //     return DateHelper.formatDateTime(
                    //       new Date(value),
                    //       'yyyy-MM-dd HH:mm:ss'
                    //     );
                    //   }

                    if (typeof value === 'string') {
                        value = value.replace(/\n/g, '\\n');
                        if (value.includes(',')) {
                            value = `"${value}"`;
                        }
                    }

                    if (c.type === 'object' && typeof value === 'object') {
                        return `"${value[c.keyName]}"`;
                    }

                    return value;
                }),
            ];

            return rowData.join(SEPARATOR);
        });

        if (grandTotal) {
            // Add empty row as spacing above
            rows.push(columns.map(() => '').join(SEPARATOR));

            // Grand total row with label
            const grandTotalRow = columns.map((c, index) => {
                // First column gets the "Grand Total" label
                if (index === 0) {
                    return 'Grand Total';
                }

                // Hardcode for grand total values
                if (['movement', 'prevBalanceUSD', 'currentBalanceUSD'].includes(c.dataIndex)) {
                    let value = grandTotal[c.dataIndex];

                    if (typeof value === 'string' && value.includes(',')) {
                        value = `"${value}"`;
                    }

                    return value ?? '';
                }

                return '';
            }).join(SEPARATOR);

            rows.push(grandTotalRow);
        }

        const csvFile = [header, ...rows].join('\n');

        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

        DateGridHelper.download(blob, filename);
    };

    static download = (blob, fileName) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };
}

export default DateGridHelper;