import React, { useState, useEffect, useRef } from 'react';
import OptionsMenu from './OptionMenu';
import NestedTable from './NestedTableV3';
import { formatNumber } from '../../helpers/common';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from './NestedTable.module.css';

const TableRow = ({
  rowId,
  row,
  dataConfig,
  expandedData,
  usedOptions,
  onOptionClick,
  onCloseNested,
  level,
  currentCategory,
  dateFilter,
  usedPayloadBreakdown,
  categoryLabel,
  currentMonthSelected,
  currentYearSelected,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAbove, setShowAbove] = useState(false);
  const [menuStyle, setMenuStyle] = useState({ position: { top: 0, left: 0 } });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemMenu, setSelectedItemMenu] = useState(null);

  let menuStylePosition = {};

  const menuRef = useRef(null);

  const renderCell = (config, rowData) => {
    if (config.render) {
      return config.render(rowData);
    }

    if (typeof rowData[config.dataIndex] === 'number') {
      return formatNumber(rowData[config.dataIndex]);
    }

    if (!rowData[config.dataIndex]) {
      return <span style={{ color: 'white' }}>No Record</span>;
    }

    return rowData[config.dataIndex];
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       setIsMenuOpen(false);
  //     }
  //   };

  //   if (expandedData?.data?.length < 4) {
  //     menuStylePosition = { bottom: '100%', position: 'absolute', top: '0' };
  //   }

  //   if (rowId === expandedData?.data?.length - 1) {
  //     menuStylePosition = { left: 0, right: 0, position: 'absolute' };
  //   }

  //   const checkPosition = () => {
  //     if (menuRef.current) {
  //       const rect = menuRef.current.getBoundingClientRect();
  //       const shouldShowAbove = rect.bottom > window.innerHeight;
  //       setShowAbove(shouldShowAbove);
  //     }
  //   };

  //   checkPosition();
  //   document.addEventListener('mousedown', handleClickOutside);
  //   window.addEventListener('scroll', checkPosition);
  //   window.addEventListener('resize', checkPosition);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     window.removeEventListener('scroll', checkPosition);
  //     window.removeEventListener('resize', checkPosition);
  //   };
  // }, [isMenuOpen]);

  // const aboveStlye =
  //   rowId >= Math.floor(expandedData?.data?.length / 3) &&
  //   rowId < Math.floor((expandedData?.data?.length * 2) / 3);

  const aboveStlye =
    expandedData?.data?.length > 5 &&
    rowId > 5 &&
    rowId < expandedData?.data?.length - 1;

  // const calculateMenuPosition = () => {
  // if (expandedData?.data?.length < 4) {
  //   menuStylePosition = { bottom: '100%', position: 'absolute', top: '0' };
  // }

  // if (rowId === expandedData?.data?.length - 1) {
  //   menuStylePosition = { left: 0, right: 0, position: 'absolute' };
  // }
  // };
  const handleClickMenu = (event) => {
    setIsMenuOpen(event.currentTarget);
  };

  return (
    <>
      <tr className={`table-row level-${level}`}>
        {dataConfig.map((config) => (
          <td key={config.dataIndex}>{renderCell(config, row)}</td>
        ))}
        <td>
          <div className="actions-cell" style={{ position: 'relative' }}>
            {!expandedData && (
              <ControlPointIcon
                onClick={(event) => handleClickMenu(event)}
                style={{ width: '50px', cursor: 'pointer' }}
              />
            )}
            {expandedData && (
              <RemoveCircleOutlineIcon
                onClick={() => onCloseNested(rowId, row)}
                style={{ width: '50px', cursor: 'pointer' }}
              />
            )}

            {isMenuOpen && (
              <div
                // style={menuStyle.position}
                // style={{ ...calculateMenuPosition() }}
                // style={menuStylePosition}
                // style={
                //   aboveStlye
                //     ? { bottom: '100%', position: 'absolute', top: 'auto' }
                //     : { left: 0, position: 'absolute', right: 0 }
                // }
                // style={{
                //   position: 'fixed',
                //   // left: '0',
                //   right: '20',
                //   top: 70,
                //   bottom: 'auto',
                //   zIndex: 1000,
                // }}
                // style={{
                //   position: 'absolute',
                // }}
                onClick={(e) => {
                  const rect = e.target.getBoundingClientRect();
                  setMenuStyle({
                    position: {
                      top: rect.top + window.scrollY, // Add scroll offset for correct positioning
                      left: rect.left + window.scrollX, // Add scroll offset for correct positioning
                    },
                  });
                  setIsMenuOpen(null);
                }}
                ref={menuRef}
                // className={showAbove ? styles.aboveMenu : styles.belowMenu}
                className={styles.belowMenu}
              >
                <OptionsMenu
                  rowId={rowId}
                  rowData={row}
                  usedOptions={usedOptions}
                  onOptionClick={(option) => {
                    onOptionClick(rowId, option, row);
                    setIsMenuOpen(null);
                  }}
                  // position={menuStylePosition}
                  currentCategory={currentCategory}
                  anchorEl={isMenuOpen}
                  open={Boolean(isMenuOpen)}
                  onClose={() => setIsMenuOpen(null)}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
      {expandedData && (
        <tr>
          <td colSpan={dataConfig.length + 1}>
            <div className="nested-content">
              <NestedTable
                dataConfig={[
                  { dataIndex: 'itemName', label: categoryLabel },
                  {
                    dataIndex: 'totalUsd',
                    label: `USD ${currentMonthSelected} ${currentYearSelected}`,
                  },
                ]}
                dataSource={expandedData.data}
                level={level + 1}
                currentCategory={currentCategory}
                usedOptionsBreakdown={usedOptions}
                dateFilter={dateFilter}
                usedPayloadBreakdown={usedPayloadBreakdown}
                currentMonthSelected={currentMonthSelected}
                currentYearSelected={currentYearSelected}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
