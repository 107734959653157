import React from 'react';
import styles from './Pagination.module.css';

const Pagination = ({
  totalItems,
  pageSize = 100,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const getPageNumbers = () => {
    const pageNumbers = [];

    // Always show first page
    pageNumbers.push(1);

    if (totalPages <= 7) {
      // For 7 or fewer pages, show all
      for (let i = 2; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    }

    // Handle different ranges
    if (currentPage <= 4) {
      // Near the start
      pageNumbers.push(2, 3, 4, 5);
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    } else if (currentPage >= totalPages - 3) {
      // Near the end
      pageNumbers.push('...');
      pageNumbers.push(
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      );
    } else {
      // In the middle
      pageNumbers.push('...');
      pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className={styles.pagination}>
      {/* <button
        className={styles.paginationButton}
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Previous
      </button> */}

      {/* {getPageNumbers().map((page) => (
        <button
          key={page}
          className={
            currentPage === page ? styles.activePage : styles.paginationButton
          }
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))} */}

      {getPageNumbers().map((page, index) =>
        page === '...' ? (
          <span key={`ellipsis-${index}`} className={styles.paginationEllipsis}>
            ...
          </span>
        ) : (
          <button
            key={page}
            className={
              currentPage === page ? styles.activePage : styles.paginationButton
            }
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        )
      )}

      {/* <button
        className={styles.paginationButton}
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </button> */}
    </div>
  );
};

export default Pagination;
